import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Footer from '../components/footer';
import Header from '../components/header';

const NotFoundPage = () => (
  <Layout>
    <SEO
      context={{
        title: 'Error: Something broke',
        description:
          'Please try again (later if still not working), we are working on it.',
      }}
    />
    <Header />
    <p>Something broke, please try again or later.</p>
    <Footer />
  </Layout>
);

export default NotFoundPage;
